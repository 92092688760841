function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
    // console.log('statusChangeCallback');
    // console.log(response);                   // The current login status of the person.
    if (response.status === 'connected') {   // Logged into your webpage and Facebook.
      testAPI();  
    } else {                                 // Not logged into your webpage or we are unable to tell.
      // console.log("Please log in")
      FB.login(function(response) {
          // console.log(response);
        }, {scope: 'public_profile,email'});
    }
  }
export function checkLoginState() {               // Called when a person is finished with the Login Button.
    // FB.getLoginStatus(function(response) {   // See the onlogin handler
    //     statusChangeCallback(response);
    // });
    return new Promise ((resolve, reject)=>{
      FB.login(function(response) {
        if(response.status === 'connected'){
          FB.api(
            '/me',
            'GET',
            {"fields":"last_name,first_name,email"}, function(response) {
                resolve(response);
            });
        }
        else{
          console.error("Facebook error");
        }
      }, {scope: 'public_profile,email'});
    })
}
// window.fbAsyncInit = function() {
//     FB.init({
//       appId            : '574243980570162',
//       autoLogAppEvents : true,
//       xfbml            : true,
//       version          : 'v12.0'
//     });
//   };
  function testAPI() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    // console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', function(response) {
    // console.log('Successful login for: ' + response.name);
    });
}